@import url('https://fonts.googleapis.com/css2?family=Shadows+Into+Light&display=swap');
@font-face {
    font-family: 'DINBold';
    src: url('../../assets/Fonts/D-DIN-Bold.otf')
}
@font-face {
    font-family: 'D-DIN';
    src: url('../../assets/Fonts/D-DIN.otf')
}
@font-face {
    font-family: 'DINItalic';
    src: url('../../assets/Fonts/D-DIN-Italic.otf')
}